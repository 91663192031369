<template lang="pug">
  .app-confirmation-remove
    .d-flex(v-if="confirmation")
      AppIconButton.undo(
        icon="undo-alt"
        @click="confirmation = false"
      )
      AppIconButton.confirm(
        icon="check-double"
        @click="$emit('delete')"
      )
    AppIconButton.remove(
      v-else
      icon="trash-alt"
      @click="confirmation = true"
    )
</template>

<script>
  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    data() {
      return {
        confirmation: false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .app-confirmation-remove
    padding: 2px
    justify-content: space-around
    cursor: pointer

    .undo,
    .confirm,
    .remove
      +icon-button($default-gray)
</style>
